var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Reports"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Reports","sub-heading":"A list custom reports"}}),_c('span',{staticClass:"has-text-warning"},[_vm._v("BETA: This feature is currently still under development.")])],1),_c('column',{staticClass:"is-5"},[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search for alerts..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(_vm.$route.query.search)?_c('action-button',{on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e(),_c('router-link',{staticClass:"button is-rounded",attrs:{"to":{
                                name: 'create-report'
                            }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("Report")])],1)],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.reports.data),function(report){return _c('div',{key:report.id,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{staticClass:"mr-2",attrs:{"to":{
                                name: 'report-manager',
                                params: {
                                    report: report.id
                                }
                            }}},[_vm._v(" "+_vm._s(report.name)+" ")]),_c('p',[_c('small',{staticClass:"has-text-grey"},[_vm._v("Updated "+_vm._s(_vm._f("asCalendar")(report.updated_at)))])])],1),_c('column',{staticClass:"is-narrow has-text-right"},[_c('index-info',{attrs:{"label":"Type","info":`${report.type} Report`}})],1)],1)],1)}),0),(_vm.reports.data.length)?_c('pager',{attrs:{"pageable":_vm.reports,"context":"Equipment","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.reports.data.length,"heading":"There are no reports available for display."}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }