<template>
    <loader v-bind="{ loading }" text="Loading Reports">
        <columns>
            <column>
                <page-heading heading="Reports" sub-heading="A list custom reports" />
                <span class="has-text-warning">BETA: This feature is currently still under development.</span>
            </column>
            <column class="is-5">
                <form>
                    <text-input v-model="filters.search" classes="has-addons is-rounded"
                        placeholder="Search for alerts...">
                        <template #right>
                            <div class="buttons has-addons is-rounded">
                                <submit-button @submit="runSearch" class="is-rounded">
                                    Search
                                </submit-button>
                                <action-button v-if="$route.query.search" @click="clearFilters">
                                    <icon icon="times" />
                                </action-button>
                                <router-link class="button is-rounded" :to="{
                                    name: 'create-report'
                                }">
                                    <icon icon="plus" />
                                    <span>Report</span>
                                </router-link>
                            </div>
                        </template>
                    </text-input>
                </form>
            </column>
        </columns>
        <columns>
            <column>
                <div class="index-rows">
                    <div class="box is-marginless" v-for="report in reports.data" :key="report.id">
                        <columns>
                            <column>
                                <router-link class="mr-2" :to="{
                                    name: 'report-manager',
                                    params: {
                                        report: report.id
                                    }
                                }">
                                    {{ report.name }}
                                </router-link>
                                <p>
                                    <small class="has-text-grey">Updated {{ report.updated_at | asCalendar }}</small>
                                </p>
                            </column>
                            <column class="is-narrow has-text-right">
                                <index-info 
                                    label="Type" 
                                    :info="`${report.type} Report`"
                                />

                            </column>
                        </columns>
                    </div>
                </div>

                <pager 
                    v-if="reports.data.length" 
                    :pageable="reports" 
                    context="Equipment" 
                    jump-controls 
                    @nav="goToPage"
                />

                <no-items-to-display 
                    :item-count="reports.data.length"
                    heading="There are no reports available for display."
                />

            </column>
        </columns>
    </loader>
</template>
<script>
import { get } from '@/api/request'
export default {

    data: () => ({
        loading: true,
        filters: {
            search: ''
        },
        reports: {
            data: []
        }
    }),

    created() {
        if (this.$route.query) {
            this.filters.search = this.$route.query.search
        }
        this.loadReports()
    },

    methods: {
        loadReports() {
            get('v1' + this.$route.fullPath, ({ data }) => {
                this.reports = data
                this.loading = false
            }, () => {
                this.loading = false
            }, { params: this.filters })
        },
        goToPage(path) {
            this.$router.push(path)
        },
        runSearch() {
            this.$router.push({
                name: 'reports',
                query: this.filters
            });
        },
        clearFilters() {
            this.filters.search = ''
            this.$router.push({
                name: 'reports',
            });
        }
    },

    watch: {
        '$route': 'loadReports'
    }

}
</script>